.swiper {
  width: 800px;
}
.swiper-slide {
  height: 300px;
  line-height: 300px;
  text-align: center;
}
.swiper-slide:nth-child(2) {
  
}
.swiper-slide:nth-child(3) {
  
}
