.backgroundSection {
  background-image: url('/public/images/hero.png');
  background-size: cover;
  background-position: center;
  /*height: 100vh;  or any desired height */
  display: flex;
}

.container {
  color: black;
  margin-top: 280px;
  margin-left: 135px;
  margin-right: 135px;
  margin-bottom: 40px;
  overflow-x: hidden;
}

.title {
  font-family: 'NexaLight', sans-serif;
  font-size: 40px;
  font-weight: bold;
}

.coming {
  font-family: 'NexaLight', sans-serif;
  font-size: 64px;
  font-weight: bold;
}

.join {
  font-family: 'NexaLight', sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 48px;
  margin-top: 75px;
  max-width: 600px;
}

.description {
  font-family: 'NexaLight', sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 44px;
  margin-top: 84px;
  max-width: 600px;
}

/* Tablet view */
@media screen and (max-width: 768px) {
  .backgroundSection {
    background-image: url('/public/images/hero_small.png');
    height: 790px;
  }

  .container {
    margin-top: 160px;
    margin-left: 90px;
    overflow-x: hidden;
  }

  .title {
    font-size: 20px;
  }

  .coming {
    font-size: 36px;
  }

  .join {
    font-size: 20px;
    line-height: 48px;
    margin-top: 75px;
    max-width: 600px;
  }

  .description {
    font-size: 16px;
    font-weight: bold;
    line-height: 44px;
    margin-top: 84px;
    max-width: 600px;
  }
}

/* Phone view */
@media screen and (max-width: 480px) {
  .backgroundSection {
    background-image: url('/public/images/hero_small.png');
    height: 490px;
  }
  
  .container {
    margin-top: 120px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
    text-align: center;
    overflow-x: hidden;
  }

  .title {
    font-size: 20px;
  }

  .coming {
    font-size: 36px;
  }

  .join {
    font-size: 20px;
    line-height: 30px;
    margin-top: 0px;
    max-width: 380px;
  }

  .description {
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    margin-top: 0px;
  }
}