.backgroundSection {
  background-image: url('/public/images/subscription.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}

.container {
  padding: 60px;
  color: black;
  text-align: center;
  overflow-x: hidden;
}

.title {
  font-family: 'NexaBold', sans-serif;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px;
}

.form {
  margin: 0 auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  max-width: 660px;
  background-color: white;
  border-radius: 12px;
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px; /* Adjust spacing between rows if needed */
}

.row:last-child {
  margin-bottom: 0;
}

.input {
  flex: 1;
  padding: 12px 20px;
  border: 1px solid #AAAAAA;
  border-radius: 12px;
  margin-right: 16px; /* Add margin-right for spacing between inputs */
}

.input:last-child {
  margin-right: 0; /* Remove margin-right for the last input in a row */
}

.button {
  flex: 0 0 auto;
  background-color: #FFD700;
  padding: 12px 20px;
  border-radius: 12px;
}


.description {
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  max-width: 680px;
  margin: 36px auto 0px auto;
}

/* Phone view */
@media screen and (max-width: 480px) {
  .container {
    padding: 30px 20px 20px 20px;
    text-align: center;
  }

  .title {
    margin-bottom: 20px;
    font-family: 'NexaBold', sans-serif;
    font-size: 24px;
    line-height: 35px;
  }

  .form {
    border-radius: 12px;
    padding: 12px;
  }

  .input {
    width: 50%;
  }
}