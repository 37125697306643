.container {
  padding: 80px 130px;
}

.title {
  color: #17365D;
}

.subtitle {
  color: #365F91;
}

.content {
  color: black;
}

/* Tablet view */
@media screen and (max-width: 768px) {
  .container {
    padding: 60px 90px;
  }
}

/* Phone view */
@media screen and (max-width: 480px) {
  .container {
    padding: 40px 20px;
  }
}