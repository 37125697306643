.footer {
  padding: 20px 0;
  color: black;
  background-color: #f8f8f8;
}

.footer__container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 30px 20px;
  border-top: 1px solid #AAAAAA;
  font-size: 20px;
  overflow-x: hidden;
}

.footer__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.footer__links {
  display: flex;
}

.footer__links a {
  text-decoration: none;
  color: #000;
  margin: 0 5px;
}

.footer__links span {
  margin: 0 5px;
}

.footer__social {
  display: flex;
}

.footer__social a {
  text-decoration: none;
  color: #000;
  margin: 0 10px;
  font-size: 18px;
}

.footer__copyright {
  font-size: 14px;
  text-align: right;
  color: #777;
  width: 100%;
  margin-top: 30px;
}

/* Phone view */
@media screen and (max-width: 480px) {
  .footer__top {
    display: block;
    align-items: center;
    width: 100%;
  }

  .footer__social {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .footer__links {
    font-size: 14px;
    justify-content: space-evenly;
  }

  .footer__copyright {
    margin: 20px auto 0 auto;
    text-align: center;
    font-size: 14px;
    color: #777;
    max-width: 300px;
  }
}