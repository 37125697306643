.container {
  position: absolute;
  top: 40px;
  left: 135px;
  z-index: 10;
  overflow-x: hidden;
}

.mobileLogo {
  display: none;
}

/* Tablet view */
@media screen and (max-width: 768px) {
  .container {
    top: 20px;
    left: 90px;
  }
  .logo {
    width: 300px;
  }
}

/* Phone view */
@media screen and (max-width: 480px) {
  .container {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
  }

  .logo {
    display: none;
  }

  .mobileLogo {
    display: block;
  }
}