/* Basic form styling */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #FFD700;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #FFC700;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-form {
      padding: 15px;
  }

  .form-group input,
  .form-group textarea {
      font-size: 0.9rem;
  }

  .submit-button {
      font-size: 0.9rem;
      padding: 8px;
  }
}

@media (max-width: 480px) {
  .contact-form {
      padding: 10px;
  }

  .form-group label {
      font-size: 0.9rem;
  }

  .form-group input,
  .form-group textarea {
      font-size: 0.8rem;
  }

  .submit-button {
      font-size: 0.8rem;
      padding: 7px;
  }
}
