.container {
  padding-bottom: 40px;
  color: black;
  text-align: center;
  overflow-x: hidden;
}

.feature {
  margin: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  box-shadow: 0 0 8px rgba(128, 128, 128, 0.5);
}

.header {
  margin: 65px auto 40px auto;
  font-size: 40px;
  font-weight: bold;
  line-height: 44px;
}

.icon {
  width: 130px;
  height: 130px;
}

.title {
  font-family: 'NexaBold', sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.description {
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  line-height: 30px;
  max-width: 340px;
  margin-top: 10px;
}

/* Phone view */
@media screen and (max-width: 480px) {
  .header {
    margin: 20px auto;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    max-width: 370px;
  }

  .container {
    padding-bottom: 20px;
  }
}